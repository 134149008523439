import { PYG, USD } from "./available-currencies";

export const formatToPyg = (value: number): string => {
  return `₲${value.toLocaleString()}`;
};

export const formatToUsd = (value: number): string => {
  return `$${value.toLocaleString()}`;
};

export const formatToCurrency = (value: number, currency: string): string => {
  if (currency === PYG) {
    return formatToPyg(value);
  } else if (currency === USD) {
    return formatToUsd(value);
  }
  return "";
};
