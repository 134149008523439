import BuyRegisterCrud from "../components/bets_register/BuyRegisterCrud";


const BettingBuyLogs = () => {
  return (
    <>
      <BuyRegisterCrud />
    </>
  );
};

export default BettingBuyLogs;
