import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  
  CLabel,
  CInput,
  CInputGroupText,
  CInputGroup,
  
} from "@coreui/react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";

import { getRoutePath } from "../../routes";
import { successAlert, warningAlert } from "../utils/messages";
import { SUCCESS } from "../../utils/constants/tags";
import BettingPlace from "../../models/betting-places";
import { getList } from "../../api/generics";
import BettingPlaceUpdateModal from "./BettingPlaceUpdateModal";
import BettingPlaceDeleteModal from "./BettingPlaceDeleteModal";

const ITEMS_PER_PAGE = 10;

const BettingPlaceList = () => {
  const fields = [
    { key: "name", _classes: ["text-center"], label: "Nombre" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];
  const isSuperUser = useSelector((state: RootState) => state.user.isSuperUser);
  const [showPlaceEditModal, setShowPlaceEditModal] = useState(false);
  const [showPlaceDeleteModal, setShowPlaceDeleteModal] = useState(false);

  const [place, setPlace] = useState<BettingPlace | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [places, setPlaces] = useState<BettingPlace[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchPlaces = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const placesStatus = await getList<BettingPlace>(
      "/betting_places/",
      limit,
      offset,
      urlParams
    );
    if (placesStatus.status === SUCCESS) {
      if (placesStatus.data !== undefined) {
        const count = placesStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setPlaces(placesStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = placesStatus.detail
        ? placesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowPlaceEditModal(true);
  };

  const onUpdateClick = (place: BettingPlace) => {
    setPlace(place);
    setShowPlaceEditModal(true);
  };

  const onDeleteClick = (place: BettingPlace) => {
    setPlace(place);
    setShowPlaceDeleteModal(true);
  };

  const onPlaceEditCancel = () => {
    setPlace(undefined);
    setShowPlaceEditModal(false);
  };

  const onPlaceEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowPlaceEditModal(false);
    setPlaces([]);
    setLoading(true);
    fetchPlaces();
  };

  const onPlaceDeleteCancel = () => {
    setPlace(undefined);
    setShowPlaceDeleteModal(false);
  };

  const onPlaceDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowPlaceDeleteModal(false);
    setPlaces([]);
    setLoading(true);
    fetchPlaces();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("bettingPlaces")) {
        return;
      }
      fetchPlaces();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Lugares</h3>
              </div>
              <div className="p-2 ">
                <CButton
                  color="primary"
                  className="float-right"
                  onClick={onAddClick}
                >
                  <BsFillPlusCircleFill />
                  &nbsp; Agregar Nuevo
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={places}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    
                    actions: (item: BettingPlace) => {
                      const editButton = (
                        <CButton
                          className="text-white"
                          color="warning"
                          onClick={() => {
                            onUpdateClick(item);
                          }}
                        >
                          <i className="fa fa-pencil"></i>
                        </CButton>
                      );
                      const deleteButton = (
                        <CButton
                          className="text-white"
                          color="danger"
                          onClick={() => {
                            onDeleteClick(item);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </CButton>
                      );
                      
                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            
                            {isSuperUser ? (
                              <>
                                
                                {editButton} {deleteButton}
                              </>
                            ) : (
                              <></>
                            )}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <BettingPlaceUpdateModal
        show={showPlaceEditModal}
        onCancel={onPlaceEditCancel}
        onSuccess={onPlaceEditSuccess}
        bettingPlace={place}
      />
      <BettingPlaceDeleteModal
        show={showPlaceDeleteModal}
        onCancel={onPlaceDeleteCancel}
        onSuccess={onPlaceDeleteSuccess}
        bettingPlace={place}
      />
      
    </>
  );
};

export default BettingPlaceList;
