interface Town {
  id: number;
  name: string;
}


export const newTown = (): Town => {
  return {
    id: 0,
    name: "",
  };
};

export default Town;
