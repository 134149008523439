

import PrizeRegisterView from "../components/bets_register/PrizeRegisterView";

const BettingPrizeRegister = () => {
  return (
    <>
      <PrizeRegisterView />
    </>
  );
};

export default BettingPrizeRegister;
