import {
  CButton,
  
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInput,
  CInputGroupText,
  CInputGroup,
} from "@coreui/react";

import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { getRoutePath } from "../../routes";
import {  warningAlert } from "../utils/messages";
import { SUCCESS } from "../../utils/constants/tags";
import { getList } from "../../api/generics";
import BuyLog from "../../models/buy-log";
import { formatDateTime } from "../utils/dates";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";

const ITEMS_PER_PAGE = 20;

const BuyRegisterCrud = () => {
  const fields = [
    { key: "operationDate", _classes: ["text-center"], label: "Fecha/Hora" },
    { key: "bettingUserName", _classes: ["text-center"], label: "Apostador" },
    { key: "bettingUserDocument", _classes: ["text-center"], label: "Nro Documento" },
    { key: "bettingUserCountryOfOrigin", _classes: ["text-center"], label: "País de Origen" },
    { key: "machineName", _classes: ["text-center"], label: "Máquina utilizada" },
    { key: "bettingPlaceName", _classes: ["text-center"], label: "Lugar de Apuesta" },
    { key: "operatorName", _classes: ["text-center"], label: "Operador" },
    { key: "townName", _classes: ["text-center"], label: "Municipio" },
    { key: "betAmount", _classes: ["text-center"], label: "Monto de Apuesta" },
  ];

  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [buylogs, setBuyLogs] = useState<BuyLog[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchBuyLogs = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const buylogsStatus = await getList<BuyLog>(
      "/buy_logs/",
      limit,
      offset,
      urlParams
    );
    if (buylogsStatus.status === SUCCESS) {
      if (buylogsStatus.data !== undefined) {
        const count = buylogsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setBuyLogs(buylogsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = buylogsStatus.detail
        ? buylogsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };


  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchBuyLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("bettingBuyLogs")) {
        return;
      }
      fetchBuyLogs();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Historial de Apuestas</h3>
              </div>
              <div className="p-2 ">
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={buylogs}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    operationDate: (item: BuyLog) => {
                      return (
                        <td className="text-center">
                          {item.operationDate
                            ? formatDateTime(new Date(item.operationDate))
                            : "-"}
                        </td>
                      );
                    },
                    betAmount: (item: BuyLog) => {
                      const betAmount = item.betAmount ? item.betAmount : 0;
                      return (
                        <td className="text-center">
                          <span className="">
                            {formatToCurrency(betAmount, PYG)}
                          </span>
                        </td>
                      );
                    },
                }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>      
    </>
  );
};

export default BuyRegisterCrud;
