
import TownList from "../components/town/TownCrud";

const TownPage = () => {
  return (
    <>
      <TownList />
    </>
  );
};

export default TownPage;
