import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";

import { createItem, getItem, ItemRequestStatus, updateItem } from "../../api/generics";
import BettingUser, { newBettingUser } from "../../models/betting-user";
import CountrySelect from "../country/CountrySelect";
import Country, { newCountry } from "../../models/country";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";


interface BettingUserFormProps {
  initialBettingUser?: BettingUser;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: (bettingUser: BettingUser) => void | Promise<void>;
}

const BettingUserForm: React.FC<BettingUserFormProps> = ({
  initialBettingUser,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingBettingUser, setEditingBettingUser] = useState<BettingUser>(
    initialBettingUser ? initialBettingUser : newBettingUser()
  );

  const [selectedCountry, setSelectedCountry] = useState<Country|null>(newCountry()
  );

  const [countrySelectorDisabled, setCountrySelectorDisabled] = useState(false);

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );

  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingBettingUser({
      ...editingBettingUser,
      name: e.target.value,
    });
  };

  const onDocumentNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingBettingUser({
      ...editingBettingUser,
      documentNumber: e.target.value,
    });
  };

  const onWriteCustomClick = (newState: boolean) => {
    setCountrySelectorDisabled(newState);
    
  };

  const onCountryCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingBettingUser({
      ...editingBettingUser,
      countryOfOrigin: e.target.value,
    });
  };

  const onCountryChange = (newCountry: Country | null) => {
    setSelectedCountry(newCountry)
    const newStrCountry:string = newCountry!==null?newCountry.name:"";

    setEditingBettingUser((editingItem) => {
      return {
        ...editingItem,
        countryOfOrigin:newStrCountry,
        countryId:newCountry!==null?newCountry.id:undefined
      };
    });
  };

  const onSave = async () => {

    setSubmitting(true);
    let toSendBettingUser = {
      ...editingBettingUser,
    };
    if (toSendBettingUser.countryOfOrigin===undefined || toSendBettingUser.countryOfOrigin===""){
      warningAlert("Debe seleccionar un País");
      setSubmitting(false);
      return;
    }
    let requestPromise: Promise<ItemRequestStatus<BettingUser>>;
    if (editingBettingUser.id === undefined || editingBettingUser.id === 0) {
      requestPromise = createItem<BettingUser>("/betting_users/create/", toSendBettingUser);
    } else {
      requestPromise = updateItem<BettingUser>(
        `/betting_users/${toSendBettingUser.id}/`,
        toSendBettingUser
      );
    }

    const bettinguserStatus = await requestPromise;

    if (bettinguserStatus.status !== SUCCESS) {
  
      if (bettinguserStatus.errors !== undefined) {
        setErrors(bettinguserStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (bettinguserStatus.detail !== undefined) {
        message = bettinguserStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess(bettinguserStatus.data!);
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingBettingUser(newBettingUser());
    setCountrySelectorDisabled(false);
    setSelectedCountry(newCountry())
  };

  const fetchCountry = async (id: number) => {
    const countryStatus = await getItem<Country>(
      `/countries/${id}/`
    );
    if (countryStatus.status === SUCCESS) {
      if (countryStatus.data !== undefined) {
        setSelectedCountry(countryStatus.data);
      }
    }
  };


  useEffect(() => {
    setEditingBettingUser(initialBettingUser ? initialBettingUser : newBettingUser());
    if(initialBettingUser?.countryId!==undefined){
      fetchCountry(initialBettingUser.countryId);
    } else if (initialBettingUser?.countryOfOrigin!==undefined){
      setCountrySelectorDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBettingUser]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingBettingUser.name}
                onChange={onNameChange}
                placeholder="Ej: Nombre del Apostador"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Documento</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingBettingUser.documentNumber}
                onChange={onDocumentNumberChange}
                placeholder="Ej: Nro de documento"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("documentNumber", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>País de Origen</CLabel>
            </CCol>
            <CCol>
              <CountrySelect
                value={
                  selectedCountry
                }
                onChange={onCountryChange}
                disabled={countrySelectorDisabled}
              ></CountrySelect>
              <FieldErrors
                errors={getFieldErrors("countryOfOrigin", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Escribir</CLabel>
            </CCol>
            <CCol md={2}>
              <CRow>
                <AnimatedCheckbox
                  value={countrySelectorDisabled}
                  onChange={onWriteCustomClick}
                ></AnimatedCheckbox>
              </CRow>
            </CCol>
          </CRow>
        </CFormGroup>
        {countrySelectorDisabled?
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>País de Origen Personalizado</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingBettingUser.countryOfOrigin}
                onChange={onCountryCustomChange}
                placeholder="Pais Personalizado"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("countryOfOrigin", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>:null}
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default BettingUserForm;
