interface BettingUser {
  id: number;
  name: string;
  documentNumber:string;
  countryOfOrigin:string;
  countryId?:number;
}

export const newBettingUser = (): BettingUser => {
  return {
    id: 0,
    name: "",
    documentNumber:"",
    countryOfOrigin:"",
  };
};

export default BettingUser;
