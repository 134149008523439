import PrizeRegisterCrud from "../components/bets_register/PrizeRegisterCrud";


const BettingPrizeLogs = () => {
  return (
    <>
      <PrizeRegisterCrud />
    </>
  );
};

export default BettingPrizeLogs;
