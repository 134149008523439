import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CTooltip,
} from "@coreui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  
  ItemRequestStatus,
  
  getItem,
} from "../../api/generics";
import "react-datetime/css/react-datetime.css";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";
import { defaultValueOnUndefined } from "../../utils/fields";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";

import BuyLog, { newBuyLog } from "../../models/buy-log";
import BettingUser from "../../models/betting-user";
import { useDate } from "../utils/real-time-date";
import { BsFillPlusCircleFill, BsPencilFill } from "react-icons/bs";
import MachineSelect from "../machine/MachineSelect";
import Machine from "../../models/machine";
import BettingPlaceSelect from "../betting_place/BettingPlaceSelect";
import BettingPlace from "../../models/betting-places";
import OperatorSelect from "../operator/OperatorSelect";
import Operator from "../../models/operator";
import Town from "../../models/town";
import TownSelect from "../town/TownSelect";
import BettingUserUpdateModal from "../betting_user/BettingUserUpdateModal";

interface BuyRegisterFormProps {
  initialOperation?: BuyLog;
  onSuccess: () => void | Promise<void>;
}

const BuyRegisterForm: React.FC<BuyRegisterFormProps> = ({
  initialOperation,
  onSuccess,
}) => {

  const [editingOperation, setEditingOperation] = useState<BuyLog>(
      initialOperation ? initialOperation : newBuyLog()
    );
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [showClientEditModal, setShowClientEditModal] = useState(false);
  const [documentToSearch, setDocumentToSearch] = useState<string>("");
  const [player, setPlayer] = useState<BettingUser | undefined>(undefined);
  const [machine, setMachine] = useState<Machine | undefined>(undefined);
  const [place, setPlace] = useState<BettingPlace| undefined>(undefined);
  const [operator, setOperator] = useState<Operator| undefined>(undefined);

  const [town, setTown] = useState<Town| undefined>(undefined);
  
  const clientInputRef = useRef<HTMLInputElement>(null);
  const currentDateTime = useDate();


  const onDocumentToSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentToSearch(e.target.value);
  };


  const onSearchClientClick = async () => {
    setloading(true);
    await fetchBettingUser(documentToSearch);
    setloading(false);
  };

  const fetchBettingUser = async (id: string) => {
    const playerStatus = await getItem<BettingUser>(`/betting_users/document/${id}/`);
    if (playerStatus.status === SUCCESS) {
      if (playerStatus.data !== undefined) {
        setPlayer(playerStatus.data);
        
        setEditingOperation((editingOperation) => {
          return {
            ...editingOperation,
            bettingUserDocument: playerStatus.data?.documentNumber,
            bettingUserCountryOfOrigin: playerStatus.data?.countryOfOrigin,
            bettingUserName: playerStatus.data?.name,
            bettingUserId: playerStatus.data?.id,
          };
        });
      }
    } else {
      const message = playerStatus.detail
        ? playerStatus.detail
        : "Error desconocido";
      warningAlert(message);
      setEditingOperation((editingOperation) => {
        return {
          ...editingOperation,
          bettingUserDocument: undefined,
          bettingUserCountryOfOrigin: undefined,
          bettingUserName: undefined,
          bettingUserId: undefined,
        };
      });
    }
  };

  const onClientAddClick = () => {
    setShowClientEditModal(true);
  };

  const onClientUpdateClick = () => {
    setShowClientEditModal(true);
  };

  const onClientEditCancel = () => {
    setShowClientEditModal(false);
  };

  const onClientEditSuccess = (newPlayer: BettingUser) => {
    successAlert("Apostador registrado con éxito!");
    setShowClientEditModal(false);
    setPlayer(newPlayer);
    setEditingOperation((editingOperation) => {
      return {
        ...editingOperation,
        bettingUserDocument: newPlayer.documentNumber,
        bettingUserCountryOfOrigin: newPlayer.countryOfOrigin,
        bettingUserName: newPlayer.name,
        bettingUserId: newPlayer.id,
      };
    });
  };

  const onMachineChange = (newMachine: Machine | null) => {
    setMachine(newMachine?newMachine:undefined)

    setEditingOperation((editingOperation) => {
      return {
        ...editingOperation,
        machineName:newMachine!==null?newMachine.name:undefined,
        machineId:newMachine!==null?newMachine.id:undefined
      };
    });
  };

  const onPlaceChange = (newPlace: BettingPlace | null) => {
    setPlace(newPlace?newPlace:undefined)

    setEditingOperation((editingOperation) => {
      return {
        ...editingOperation,
        bettingPlaceName:newPlace!==null?newPlace.name:undefined,
        bettingPlaceId:newPlace!==null?newPlace.id:undefined
      };
    });
  };

  const onOperatorChange = (newoperator: Operator | null) => {
    setOperator(newoperator?newoperator:undefined)

    setEditingOperation((editingOperation) => {
      return {
        ...editingOperation,
        operatorName:newoperator!==null?newoperator.name:undefined,
        operatorId:newoperator!==null?newoperator.id:undefined
      };
    });
  };

  const onTownChange = (newTown: Town | null) => {
    setTown(newTown?newTown:undefined)

    setEditingOperation((editingOperation) => {
      return {
        ...editingOperation,
        townName:newTown!==null?newTown.name:undefined,
        townId:newTown!==null?newTown.id:undefined
      };
    });
  };



  const onBetAmountChange = (newAmount: number | undefined) => {
    setEditingOperation({
      ...editingOperation,
      betAmount: newAmount,
    });
  };

  const onSave = async () => {
    setSubmitting(true);

    let toSendCashRegisterOperation: BuyLog = {
      ...editingOperation,
      operationDate: moment().toISOString()
    };
    let requestPromise: Promise<ItemRequestStatus<BuyLog>>;
    if (editingOperation.id === undefined || editingOperation.id===0) {
      requestPromise = createItem<BuyLog>(
        "/buy_logs/create/",
        toSendCashRegisterOperation
      );
    } else {
      warningAlert("Error inesperado. no se pudo encontrar ID")
      setSubmitting(false);
      return;
    }

    const logStatus = await requestPromise;

    if (logStatus.status !== SUCCESS) {
      if (logStatus.errors !== undefined) {
        setErrors(logStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (logStatus.detail !== undefined) {
        message = logStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const clearForm = () => {
    setEditingOperation(newBuyLog());
    setDocumentToSearch("");
    setMachine(undefined);
    setPlace(undefined);
    setOperator(undefined);
    setTown(undefined)
  };

  useEffect(() => {
    setEditingOperation(
      initialOperation ? initialOperation : newBuyLog()
    );
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOperation]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
            <CRow>
              <CCol>
                <h3>Datos de Apostador</h3>
              </CCol>
            </CRow>
            <CRow>
              <CCol md={5} lg={4} xl={3} className={"mt-2"}>
                <CLabel>Nro. de Documento:</CLabel>
              </CCol>
              <CCol md={5} lg={4} xl={3}>
                <CInput
                  innerRef={clientInputRef}
                  type="text"
                  value={documentToSearch}
                  onChange={onDocumentToSearchChange}
                  placeholder="Ingrese el Documento"
                ></CInput>
              </CCol>
              <CCol md={2} xl={2} lg={2}>
                <CButton
                  className="btn btn-primary"
                  onClick={onSearchClientClick}
                  disabled={loading}
                >
                  Buscar
                </CButton>
                <CTooltip content={"Nuevo Apostador"}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onClientAddClick}
                  >
                    <BsFillPlusCircleFill />
                  </CButton>
                </CTooltip>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol>
                <CLabel>Apostador:</CLabel>
              </CCol>
              <CCol>{defaultValueOnUndefined("-", player?.name)}</CCol>
              <CCol>
                <CLabel>Documento:</CLabel>
              </CCol>
              <CCol>{defaultValueOnUndefined("-", player?.documentNumber)}</CCol>
            </CRow>
            <CRow>
              <CCol md={3} xl={3} lg={2}>
                <CLabel>Nacionalidad:</CLabel>
              </CCol>
              <CCol md={3} xl={3} lg={2}>
                {defaultValueOnUndefined("-", player?.countryOfOrigin)}
              </CCol>
              {player?.name ? (
                <CCol md={2} xl={2} lg={2}>
                  <CButton color="primary" onClick={onClientUpdateClick}>
                    <BsPencilFill /> Editar Cliente
                  </CButton>
                </CCol>
              ) : (
                <></>
              )}
            </CRow>
          </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Máquina utilizada:</CLabel>
            </CCol>
            <CCol md={9}>
              <MachineSelect
                value={
                  machine?machine:null
                }
                onChange={onMachineChange}
              ></MachineSelect>
              <FieldErrors
                errors={getFieldErrors("machineId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Lugar de la apuesta:</CLabel>
            </CCol>
            <CCol md={9}>
              <BettingPlaceSelect
                value={
                  place?place:null
                }
                onChange={onPlaceChange}
              ></BettingPlaceSelect>
              <FieldErrors
                errors={getFieldErrors("bettingPlaceId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Operador:</CLabel>
            </CCol>
            <CCol md={9}>
              <OperatorSelect
                value={
                  operator?operator:null
                }
                onChange={onOperatorChange}
              ></OperatorSelect>
              <FieldErrors
                errors={getFieldErrors("operatorId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Municipio:</CLabel>
            </CCol>
            <CCol md={9}>
              <TownSelect
                value={
                  town?town:null
                }
                onChange={onTownChange}
              ></TownSelect>
              <FieldErrors
                errors={getFieldErrors("townId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>


        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Monto de Apuesta:</CLabel>
            </CCol>
            <CCol md={9}>

              <CurrencyField
                currency={PYG}
                onChange={onBetAmountChange}
                value={editingOperation.betAmount?editingOperation.betAmount:0}
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("betAmount", errors) as string[]}
              ></FieldErrors>
            </CCol>
              
            
          </CRow>
        </CFormGroup>          
        <CFormGroup>
          <CRow className={"mt-2"}>
            <CCol md={3}>
              <CLabel className={"mt-2"}>Fecha/Hora:</CLabel>
            </CCol>
            <CCol md={3}>
              <p>{currentDateTime}</p>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton
              type="submit"
              color="primary"
              onClick={onSave}
              disabled={submitting}
            >
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
        <BettingUserUpdateModal
          bettingUser={player}
          show={showClientEditModal}
          onCancel={onClientEditCancel}
          onSuccess={onClientEditSuccess}
        />
      </fieldset>
    </>
  );
};

export default BuyRegisterForm;
