import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { successAlert } from "../utils/messages";

import { getRoutePath } from "../../routes";

import BuyLog, { newBuyLog } from "../../models/buy-log";
import BuyRegisterForm from "./BuyRegisterForm";

const BuyRegisterView = () => {

  const [registerOperation,] = useState<BuyLog>(newBuyLog());
  
  const history = useHistory();


  const onRegisterSuccess = () => {
    successAlert("Operación registrada con exito!");
    history.push(getRoutePath("dashboard"));
  };

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol lg="12">
            <CCard>
              <CCardHeader className="d-flex flex-row mb-3">
                <div className="p-2 ">
                  <h3>
                    {"REGISTRO DE APUESTA (Compra de Monedas)"}
                  </h3>
                </div>
                
              </CCardHeader>
              <CCardBody>
                <BuyRegisterForm initialOperation={registerOperation} onSuccess={onRegisterSuccess}></BuyRegisterForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default BuyRegisterView;

