import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";

import TownForm from "./TownForm";
import Town, { newTown } from "../../models/town";

interface TownUpdateModalProps {
  show: boolean;
  town?: Town;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const TownUpdateModal: React.FC<TownUpdateModalProps> = ({
  show,
  town,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialTown, setInitialTown] = useState<Town>(
    town ? town : newTown()
  );

  const modalTitle = town
    ? `Editar Municipio: ${town.name}`
    : `Crear Municipio nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialTown(newTown());
    onCancel();
  };

  useEffect(() => {
    setInitialTown(town ? town : newTown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [town]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <TownForm
            initialTown={initialTown}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default TownUpdateModal;
