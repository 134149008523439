import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";

import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";
import Town, { newTown } from "../../models/town";


interface TownFormProps {
  initialTown?: Town;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const TownForm: React.FC<TownFormProps> = ({
  initialTown,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingTown, setEditingTown] = useState<Town>(
    initialTown ? initialTown : newTown()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );

  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingTown({
      ...editingTown,
      name: e.target.value,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendTown = {
      ...editingTown,
    };
    let requestPromise: Promise<ItemRequestStatus<Town>>;
    if (editingTown.id === undefined || editingTown.id === 0) {
      requestPromise = createItem<Town>("/towns/create/", toSendTown);
    } else {
      requestPromise = updateItem<Town>(
        `/towns/${toSendTown.id}/`,
        toSendTown
      );
    }

    const townStatus = await requestPromise;

    if (townStatus.status !== SUCCESS) {
  
      if (townStatus.errors !== undefined) {
        setErrors(townStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (townStatus.detail !== undefined) {
        message = townStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingTown(newTown());
  };

  useEffect(() => {
    setEditingTown(initialTown ? initialTown : newTown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTown]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingTown.name}
                onChange={onNameChange}
                placeholder="Ej: Nombre del municipio"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default TownForm;
