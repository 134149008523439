interface BuyLog {
  id: number;
  bettingUserName?: string;
  bettingUserDocument?:string;
  bettingUserCountryOfOrigin?:string;
  bettingUserId?: number;
  machineName?:string;
  machineId?:number;
  bettingPlaceName?: string;
  bettingPlaceId?:number;
  operatorName?:string;
  operatorId?: number;
  townName?:string;
  townId?:number;
  operationDate?: string;
  betAmount?:number;
}

export const newBuyLog = (): BuyLog => {
  return {
    id:0
  };
};

export default BuyLog;
