import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";


import Town from "../../models/town";

const RECORS_PER_SEARCH = 20;

type TownSelectProps = {
  value: Town | null;
  onChange: (_: Town | null) => void;
  disabled?:boolean;
};

const TownSelect: React.FC<TownSelectProps> = ({
  value,
  onChange,
  disabled
}) => {
  
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | Town
      | { options: Town[]; label?: string }
    )[]
  ): Promise<
    Response<Town, { options: Town[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();


    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<Town>(
      "/towns/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Town[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Municipio"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: Town) => `${option.name}`}
      getOptionValue={(option: Town) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default TownSelect;
