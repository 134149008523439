
import OperatorList from "../components/operator/OperatorCrud";

const OperatorPage = () => {
  return (
    <>
      <OperatorList />
    </>
  );
};

export default OperatorPage;
