
import BettingUserList from "../components/betting_user/BettingUserCrud";

const BettingUserPage = () => {
  return (
    <>
      <BettingUserList />
    </>
  );
};

export default BettingUserPage;
