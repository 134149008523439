import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import { deleteItem } from "../../api/generics";
import Country from "../../models/country";

interface CountryDeleteModalProps {
  show: boolean;
  country?: Country;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const CountryDeleteModal: React.FC<CountryDeleteModalProps> = ({
  show,
  country,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = country
    ? `Está seguro de que quiere eliminar el Pais ${country.name}`
    : "";

  const onDeleteClick = async () => {
    if (country !== undefined) {
      setSubmitting(true);
      const countryStatus = await deleteItem<Country>(`/countries/${country.id}/`, country);

      if (countryStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (countryStatus.detail !== undefined) {
          message = countryStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default CountryDeleteModal;
