import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";

import OperatorForm from "./OperatorForm";
import Operator, { newOperator } from "../../models/operator";

interface OperatorUpdateModalProps {
  show: boolean;
  operator?: Operator;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const OperatorUpdateModal: React.FC<OperatorUpdateModalProps> = ({
  show,
  operator,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialOperator, setInitialOperator] = useState<Operator>(
    operator ? operator : newOperator()
  );

  const modalTitle = operator
    ? `Editar Operador: ${operator.name}`
    : `Crear Operador nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialOperator(newOperator());
    onCancel();
  };

  useEffect(() => {
    setInitialOperator(operator ? operator : newOperator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <OperatorForm
            initialOperator={initialOperator}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default OperatorUpdateModal;
