import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";

import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";
import Country, { newCountry } from "../../models/country";


interface CountryFormProps {
  initialCountry?: Country;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const CountryForm: React.FC<CountryFormProps> = ({
  initialCountry,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingCountry, setEditingCountry] = useState<Country>(
    initialCountry ? initialCountry : newCountry()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );

  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingCountry({
      ...editingCountry,
      name: e.target.value,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendCountry = {
      ...editingCountry,
    };
    let requestPromise: Promise<ItemRequestStatus<Country>>;
    if (editingCountry.id === undefined || editingCountry.id === 0) {
      requestPromise = createItem<Country>("/countries/create/", toSendCountry);
    } else {
      requestPromise = updateItem<Country>(
        `/countries/${toSendCountry.id}/`,
        toSendCountry
      );
    }

    const countryStatus = await requestPromise;

    if (countryStatus.status !== SUCCESS) {
  
      if (countryStatus.errors !== undefined) {
        setErrors(countryStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (countryStatus.detail !== undefined) {
        message = countryStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingCountry(newCountry());
  };

  useEffect(() => {
    setEditingCountry(initialCountry ? initialCountry : newCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCountry]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingCountry.name}
                onChange={onNameChange}
                placeholder="Ej: Nombre del Pais"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default CountryForm;
