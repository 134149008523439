import {
  CButton,
  CCard,
  CCardBody,
  CCardTitle,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getBuyReportByDateInterval } from "../../api/report";
import { SUCCESS } from "../../utils/constants/tags";
import PDFReportComponent from "./components/PDFPreviewfComponent";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { dateToRFC3339 } from "../utils/datetime";
const ReportPage = () => {
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);

  const [showPdfContainer, setShowPDFContainer] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [startTimeField, setStartTimeField] = useState<Date | undefined>(
    getDateFromURL(searchParams, "start_time")
  );
  const [endTimeField, setEndTimeField] = useState<Date | undefined>(
    getDateFromURL(searchParams, "start_time")
  );

  const [search, setSearch] = useState<string |undefined >(getValueFromURL(searchParams,"search"));

  const [file, setFile] = useState<string>("");
  const generateMachinesCashFlowReport = async (
    startTime: string,
    endTime: string,
    search: string|undefined
  ) => {
    const searchSend = search ? search: "";
    const reportResponse = await getBuyReportByDateInterval(
      startTime,
      endTime,
      searchSend
    );
    setGeneratingReport(false);

    if (reportResponse.status === SUCCESS) {
      const file = window.URL.createObjectURL(reportResponse.data as Blob);
      setFile(file);
    }
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    let urlParams = new URLSearchParams();
    urlParams.set("start_time", dateToRFC3339(startTimeField as Date));
    urlParams.set("end_time", dateToRFC3339(endTimeField as Date));
    if (search!==undefined){
      urlParams.set("search", search);
    } else {
      urlParams.delete("search")
    }

    let url = `?${urlParams.toString()}`;
    history.push(url);
  };
  const onStartTimeChange = async (value: string | moment.Moment) => {
    if (
      (value as moment.Moment) !== undefined &&
      moment(value, true).isValid()
    ) {
      const startDate = (value as moment.Moment).toDate();
      setStartTimeField(startDate);
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onEndTimeChange = async (value: string | moment.Moment) => {
    if (
      (value as moment.Moment) !== undefined &&
      moment(value, true).isValid()
    ) {
      const startDate = (value as moment.Moment).toDate();
      setEndTimeField(startDate);
    }
  };
  const processReport = (location: any) => {
    let startTime: string | null = "";
    let endTime: string | null = "";
    let search: string | null |undefined = "";
    let params = new URLSearchParams(location.search);
    if (params.has("start_time") && params.has("end_time")) {
      startTime = params.get("start_time");
      endTime = params.get("end_time");
    }
    if (params.has("search")) {
      search = params.get("search");
    }
    if (search === null) search = undefined
    if (startTime && endTime) {
      setGeneratingReport(true);
      setShowPDFContainer(true);
      generateMachinesCashFlowReport(startTime, endTime, search);
    }
  };
  useEffect(() => {
    processReport(history.location);
    return history.listen((location) => {
      processReport(location);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  return (
    <>
      <CCard style={{ width: "100%" }}>
        <CCardBody>
          <CCardTitle>Filtros</CCardTitle>
          <CForm action="" onSubmit={onSubmit} method="get">
            <CRow>
              <CCol md="3">
                <CFormGroup className="form-floating mb-3">
                  <CLabel htmlFor="nf-email">Fecha Inicio</CLabel>
                  <Datetime
                    className="col-md-9 pl-0"
                    value={startTimeField}
                    onChange={onStartTimeChange}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    utc
                    closeOnSelect={true}
                  />
                </CFormGroup>
              </CCol>
              <CCol md="3">
                <CFormGroup>
                  <CLabel htmlFor="nf-password">Fecha Fin</CLabel>
                  <Datetime
                    className="col-md-9"
                    value={endTimeField}
                    onChange={onEndTimeChange}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    utc
                    closeOnSelect={true}
                  />
                </CFormGroup>
              </CCol>
              <CCol md="3">
                <CFormGroup>
                  <CLabel htmlFor="nf-password">Búsqueda</CLabel>
                  <CInput
                      className="col-md-9"
                      type="text"
                      placeholder="Buscar"
                      onChange={onSearchChange}
                      value={search}

                    />
                </CFormGroup>
              </CCol>
              <CCol md="2">
                <CFormGroup>
                  <CButton
                    className="report-button"
                    variant="outline"
                    type="submit"
                    color="primary"
                  >
                    <CIcon name="cil-cloud-download" />
                    &nbsp; Generar Reporte
                  </CButton>
                </CFormGroup>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      {showPdfContainer ? PDFReportComponent(generatingReport, file) : <></>}
    </>
  );
};
const getDateFromURL = (params: URLSearchParams, key: string) => {
  if (params.has(key)) {
    return new Date(params.get(key) as string);
  }
  return undefined;
};

const getValueFromURL = (params: URLSearchParams, key: string) => {
  if (params.has(key)) {
    return params.get(key) as string;
  }
  return undefined;
};

export default ReportPage;
