interface Operator {
  id: number;
  name: string;
  documentNumber:string;
}


export const newOperator = (): Operator => {
  return {
    id: 0,
    name: "",
    documentNumber:""
  };
};

export default Operator;
