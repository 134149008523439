import { REPORT_SERVER_ENDPOINT } from "../config/api";
import { FAILED, SUCCESS } from "../utils/constants/tags";
import { fetchAuthenticated } from "./utils";

export const getMachinesCashFlowReportByDateInterval = async (
  startTime: string,
  endTime: string
) => {
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
  });
  const url = REPORT_SERVER_ENDPOINT + "/reports/cashflow/?" + params.toString();

  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }
  const header = response.headers.get("Content-Disposition");
  const reportData = await response.blob();

  const parts = header!.split(";");
  const fileName = parts[1].split("=")[1];

  return {
    status: SUCCESS,
    data: reportData,
    fileName: fileName,
  };
};


export const getBuyReportByDateInterval = async (
  startTime: string,
  endTime: string,
  search: string
) => {
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    search:search
  });
  const url = REPORT_SERVER_ENDPOINT + "/reports/buy_logs/?" + params.toString();

  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }
  const header = response.headers.get("Content-Disposition");
  const reportData = await response.blob();

  const parts = header!.split(";");
  const fileName = parts[1].split("=")[1];

  return {
    status: SUCCESS,
    data: reportData,
    fileName: fileName,
  };
};


export const getPrizeReportByDateInterval = async (
  startTime: string,
  endTime: string,
  search: string
) => {
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    search:search
  });
  const url = REPORT_SERVER_ENDPOINT + "/reports/prize_logs/?" + params.toString();

  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }
  const header = response.headers.get("Content-Disposition");
  const reportData = await response.blob();

  const parts = header!.split(";");
  const fileName = parts[1].split("=")[1];

  return {
    status: SUCCESS,
    data: reportData,
    fileName: fileName,
  };
};
