import BettingPlaceList from "../components/betting_place/BettingPlaceCrud";

const BettingPlacePage = () => {
  return (
    <>
      <BettingPlaceList />
    </>
  );
};

export default BettingPlacePage;
