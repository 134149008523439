import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";

import CountryForm from "./CountryForm";
import Country, { newCountry } from "../../models/country";

interface CountryUpdateModalProps {
  show: boolean;
  country?: Country;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const CountryUpdateModal: React.FC<CountryUpdateModalProps> = ({
  show,
  country,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialCountry, setInitialCountry] = useState<Country>(
    country ? country : newCountry()
  );

  const modalTitle = country
    ? `Editar País: ${country.name}`
    : `Crear País nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialCountry(newCountry());
    onCancel();
  };

  useEffect(() => {
    setInitialCountry(country ? country : newCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CountryForm
            initialCountry={initialCountry}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default CountryUpdateModal;
