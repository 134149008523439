import CountryList from "../components/country/CountryCrud";

const CountryPage = () => {
  return (
    <>
      <CountryList />
    </>
  );
};

export default CountryPage;
