interface BettingPlace {
  id: number;
  name: string;
}


export const newBettingPlace = (): BettingPlace => {
  return {
    id: 0,
    name: "",
  };
};

export default BettingPlace;
