import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from "@coreui/react";

//import CIcon from '@coreui/icons-react';

import {
  minimizeDashboard,
  showDashboard,
} from "../../redux/actions/dashboard";
import { RootState } from "../../store";

// sidebar nav config
import { getRouteName, getRoutePath } from "../../routes";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.dashboard.sidebarShow);
  const minimize = useSelector(
    (state: RootState) => state.dashboard.sidebarMinimize
  );
  const isSuperUser = useSelector((state: RootState) => state.user.isSuperUser);

  const isConfigurationNavPage = () => {
    return (
      window.location.pathname === getRoutePath("bettingUsers") ||
      window.location.pathname === getRoutePath("operators") ||
      window.location.pathname === getRoutePath("bettingPlaces") ||
      window.location.pathname === getRoutePath("countries") ||
      window.location.pathname === getRoutePath("towns") ||
      window.location.pathname === getRoutePath("groups") ||
      window.location.pathname === getRoutePath("users")
    );
  };

  const [showConfigurationNav, setShowConfigurationNav] = useState(
    isConfigurationNavPage()
  );

  const history = useHistory();

  useEffect(() => {
    return history.listen((_) => {
      setShowConfigurationNav(isConfigurationNavPage());
      
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  return (
    <CSidebar
      show={show}
      onShowChange={(val: boolean) => {
        dispatch(showDashboard(val));
        window.localStorage.setItem("sidebarShow", val ? "true" : "false");
      }}
      minimize={minimize}
      onMinimizeChange={(val: boolean) => {
        dispatch(minimizeDashboard(!val));
        window.localStorage.setItem("sidebarMinimize", !val ? "true" : "false");
      }}
      dropdownMode={"noAction"}
    >
      <CSidebarBrand className="d-md-down-none" to="/"></CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem
          name={getRouteName("dashboard")}
          to={getRoutePath("dashboard")}
          icon={"cil-home"}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("buyLogRegister")}
          to={getRoutePath("buyLogRegister")}
          icon={<i className="fa fa-money mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("prizeLogRegister")}
          to={getRoutePath("prizeLogRegister")}
          icon={<i className="fa fa-dollar mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("bettingBuyLogs")}
          to={getRoutePath("bettingBuyLogs")}
          icon={<i className="fa fa-list mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("bettingPrizeLogs")}
          to={getRoutePath("bettingPrizeLogs")}
          icon={<i className="fa fa-list mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("maps")}
          to={getRoutePath("maps")}
          icon={<i className="fa fa-map mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("machines")}
          to={getRoutePath("machines")}
          icon={<i className="fa fa-gamepad mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("reports")}
          to={getRoutePath("reports")}
          icon={<i className="fa fa-map mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("buyReports")}
          to={getRoutePath("buyReports")}
          icon={<i className="fa fa-map mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("prizeReports")}
          to={getRoutePath("prizeReports")}
          icon={<i className="fa fa-map mr-4"></i>}
        ></CSidebarNavItem>
        {isSuperUser ? (
          <CSidebarNavDropdown
            name="Configuraciones"
            icon={<i className="c-sidebar-nav-icon fa fa-cogs"></i>}
            show={showConfigurationNav}
          >
            <CSidebarNavItem
              name={getRouteName("bettingUsers")}
              to={getRoutePath("bettingUsers")}
              icon={<i className="fa fa-user mr-4"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("operators")}
              to={getRoutePath("operators")}
              icon={<i className="fa fa-user mr-4"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("bettingPlaces")}
              to={getRoutePath("bettingPlaces")}
              icon={<i className="fa fa-building mr-4"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("countries")}
              to={getRoutePath("countries")}
              icon={<i className="fa fa-globe mr-4"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("towns")}
              to={getRoutePath("towns")}
              icon={<i className="fa fa-list mr-4"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("groups")}
              to={getRoutePath("groups")}
              icon={<i className="fa fa-object-group mr-4"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("users")}
              to={getRoutePath("users")}
              icon={<i className="fa fa-users mr-4"></i>}
            ></CSidebarNavItem>
          </CSidebarNavDropdown>
        ) : (
          <></>
        )}
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
