import DashboardPage from "./dashboard-pages/DashboardPage";
import MachineCrudPage from "./dashboard-pages/MachineCrudPage";
import GroupCrudPage from "./dashboard-pages/GroupCrudPage";
import UserCrudPage from "./dashboard-pages/UserCrudPage";
import MachineDetailPage from "./dashboard-pages/MachineDetailPage";
import MapsPage from "./dashboard-pages/MapsPage";
import Unauthorized from "./pages/Error401";
import ReportPage from "./dashboard-pages/ReportPage";
import BettingPlacePage from "./dashboard-pages/BettingPlacePage";
import CountryPage from "./dashboard-pages/CountryPage";
import TownPage from "./dashboard-pages/TownPage";
import OperatorPage from "./dashboard-pages/OperatorPage";
import BettingUserPage from "./dashboard-pages/BettingUserPage";
import BettingBuyRegister from "./dashboard-pages/BettingBuyRegister";
import BettingBuyLogs from "./dashboard-pages/BettingBuyLogs";
import BettingPrizeRegister from "./dashboard-pages/BettingPrizeRegister";
import BettingPrizeLogs from "./dashboard-pages/BettingPrizeLogs";
import BuyRegistersReportPage from "./dashboard-pages/BuyRegistersReportPage";
import PrizeRegistersReportPage from "./dashboard-pages/PrizeRegistersReportPage";

interface Route {
  path: string;
  exact?: boolean | undefined;
  name: string;
  component?: any;
  superUserRequired?: boolean | undefined;
}

interface RouteMap {
  [index: string]: Route;
}

const routes: RouteMap = {
  dashboard: {
    path: "/dashboard/",
    name: "Inicio",
    exact: true,
    component: DashboardPage,
    superUserRequired: false,
  },
  machines: {
    path: "/machines/",
    name: "Máquinas",
    exact: true,
    component: MachineCrudPage,
    superUserRequired: false,
  },
  machineDetail: {
    path: "/machine/:id/",
    name: "Detalle de Máquina",
    exact: true,
    component: MachineDetailPage,
    superUserRequired: false,
  },
  groups: {
    path: "/groups/",
    name: "Grupos",
    exact: true,
    component: GroupCrudPage,
    superUserRequired: true,
  },
  users: {
    path: "/users/",
    name: "Usuarios",
    exact: true,
    component: UserCrudPage,
    superUserRequired: true,
  },
  bettingPlaces: {
    path: "/betting_places/",
    name: "Lugares",
    exact: true,
    component: BettingPlacePage,
    superUserRequired: true,
  },
  countries: {
    path: "/countries/",
    name: "Paises",
    exact: true,
    component: CountryPage,
    superUserRequired: true,
  },
  towns: {
    path: "/towns/",
    name: "Municipios",
    exact: true,
    component: TownPage,
    superUserRequired: true,
  },
  operators: {
    path: "/operators/",
    name: "Operadores",
    exact: true,
    component: OperatorPage,
    superUserRequired: true,
  },
  bettingUsers: {
    path: "/betting_users/",
    name: "Jugadores",
    exact: true,
    component: BettingUserPage,
    superUserRequired: true,
  },
  buyLogRegister: {
    path: "/buy_register/",
    name: "Registrar Apuesta",
    exact: true,
    component: BettingBuyRegister,
    superUserRequired: false,
  },
  bettingBuyLogs: {
    path: "/buy_logs/",
    name: "Historial Apuestas",
    exact: true,
    component: BettingBuyLogs,
    superUserRequired: false,
  },
  prizeLogRegister: {
    path: "/prize_register/",
    name: "Registrar Premio",
    exact: true,
    component: BettingPrizeRegister,
    superUserRequired: false,
  },
  bettingPrizeLogs: {
    path: "/prize_logs/",
    name: "Historial Premios",
    exact: true,
    component: BettingPrizeLogs,
    superUserRequired: false,
  },
  maps: {
    path: "/maps/",
    name: "Mapa",
    exact: true,
    component: MapsPage,
    superUserRequired: false,
  },
  reports: {
    path: "/reports/",
    name: "Reportes",
    exact: true,
    component: ReportPage,
    superUserRequired: false,
  },
  buyReports: {
    path: "/buy_reports/",
    name: "Reporte de Apuestas",
    exact: true,
    component: BuyRegistersReportPage,
    superUserRequired: false,
  },
  prizeReports: {
    path: "/prize_reports/",
    name: "Reporte de Premios",
    exact: true,
    component: PrizeRegistersReportPage,
    superUserRequired: false,
  },
  unauthorized: {
    path: "/unauthorized/",
    name: "No esta autorizado",
    exact: true,
    component: Unauthorized,
    superUserRequired: false,
  },
};

export const getRoutePath = (key: string) => {
  return routes[key].path;
};
export const getRouteName = (key: string) => {
  return routes[key].name;
};

export default routes;
