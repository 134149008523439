import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";

import BettingUserForm from "./BettingUserForm";
import BettingUser, { newBettingUser } from "../../models/betting-user";

interface BettingUserUpdateModalProps {
  show: boolean;
  bettingUser?: BettingUser;
  onCancel: () => void | Promise<void>;
  onSuccess: (bettingUser:BettingUser) => void | Promise<void>;
}

const BettingUserUpdateModal: React.FC<BettingUserUpdateModalProps> = ({
  show,
  bettingUser,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialBettingUser, setInitialBettingUser] = useState<BettingUser>(
    bettingUser ? bettingUser : newBettingUser()
  );

  const modalTitle = bettingUser
    ? `Editar Jugador: ${bettingUser.name}`
    : `Crear Jugador nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialBettingUser(newBettingUser());
    onCancel();
  };

  useEffect(() => {
    setInitialBettingUser(bettingUser ? bettingUser : newBettingUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bettingUser]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <BettingUserForm
            initialBettingUser={initialBettingUser}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default BettingUserUpdateModal;
