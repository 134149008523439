import { CCard, CCardBody, CCardTitle, CCol, CRow } from "@coreui/react";
import Loading from "../../loading/Loading";

const PDFReportComponent = (generatingReport: boolean, fileURL: string) => {
  return (
    <>
      {generatingReport ? (
        <Loading />
      ) : (
        <>
          <CCard style={{ width: "100%" }}>
            <CCardBody>
              <CCardTitle>Reporte de Apuestas</CCardTitle>
              <CRow>
                <CCol md="12">
                  <iframe
                    src={fileURL}
                    width="100%"
                    height="700px"
                    title="reporte"
                  ></iframe>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </>
      )}
    </>
  );
};

export default PDFReportComponent;
