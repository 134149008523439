import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";


import Country from "../../models/country";

const RECORS_PER_SEARCH = 20;

type CountrySelectProps = {
  value: Country | null;
  onChange: (_: Country | null) => void;
  disabled?:boolean;
};

const CountrySelect: React.FC<CountrySelectProps> = ({
  value,
  onChange,
  disabled
}) => {
  
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | Country
      | { options: Country[]; label?: string }
    )[]
  ): Promise<
    Response<Country, { options: Country[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();


    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<Country>(
      "/countries/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Country[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un País"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: Country) => `${option.name}`}
      getOptionValue={(option: Country) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default CountrySelect;
