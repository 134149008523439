interface Country {
  id: number;
  name: string;
}


export const newCountry = (): Country => {
  return {
    id: 0,
    name: "",
  };
};

export default Country;
