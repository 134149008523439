import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";


import BettingPlace from "../../models/betting-places";

const RECORS_PER_SEARCH = 20;

type BettingPlaceSelectProps = {
  value: BettingPlace | null;
  onChange: (_: BettingPlace | null) => void;
  disabled?:boolean;
};

const BettingPlaceSelect: React.FC<BettingPlaceSelectProps> = ({
  value,
  onChange,
  disabled
}) => {
  
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | BettingPlace
      | { options: BettingPlace[]; label?: string }
    )[]
  ): Promise<
    Response<BettingPlace, { options: BettingPlace[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();


    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<BettingPlace>(
      "/betting_places/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: BettingPlace[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Lugar de Apuesta"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: BettingPlace) => `${option.name}`}
      getOptionValue={(option: BettingPlace) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default BettingPlaceSelect;
