import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";


import Operator from "../../models/operator";

const RECORS_PER_SEARCH = 20;

type OperatorSelectProps = {
  value: Operator | null;
  onChange: (_: Operator | null) => void;
  disabled?:boolean;
};

const OperatorSelect: React.FC<OperatorSelectProps> = ({
  value,
  onChange,
  disabled
}) => {
  
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | Operator
      | { options: Operator[]; label?: string }
    )[]
  ): Promise<
    Response<Operator, { options: Operator[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();


    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<Operator>(
      "/operators/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Operator[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Operador"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: Operator) => `${option.name}`}
      getOptionValue={(option: Operator) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default OperatorSelect;
