import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import BettingPlace, { newBettingPlace } from "../../models/betting-places";
import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";


interface BettingPlaceFormProps {
  initialBettingPlace?: BettingPlace;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const BettingPlaceForm: React.FC<BettingPlaceFormProps> = ({
  initialBettingPlace,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingBettingPlace, setEditingBettingPlace] = useState<BettingPlace>(
    initialBettingPlace ? initialBettingPlace : newBettingPlace()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );

  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingBettingPlace({
      ...editingBettingPlace,
      name: e.target.value,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendPlace = {
      ...editingBettingPlace,
    };
    let requestPromise: Promise<ItemRequestStatus<BettingPlace>>;
    if (editingBettingPlace.id === undefined || editingBettingPlace.id === 0) {
      requestPromise = createItem<BettingPlace>("/betting_places/create/", toSendPlace);
    } else {
      requestPromise = updateItem<BettingPlace>(
        `/betting_places/${toSendPlace.id}/`,
        toSendPlace
      );
    }

    const placeStatus = await requestPromise;

    if (placeStatus.status !== SUCCESS) {
      console.log(placeStatus)
      if (placeStatus.errors !== undefined) {
        setErrors(placeStatus.errors);
        console.log(placeStatus)
      }

      let message = "Ha ocurrido un error!!";
      if (placeStatus.detail !== undefined) {
        message = placeStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingBettingPlace(newBettingPlace());
  };

  useEffect(() => {
    setEditingBettingPlace(initialBettingPlace ? initialBettingPlace : newBettingPlace());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBettingPlace]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingBettingPlace.name}
                onChange={onNameChange}
                placeholder="Ej: Nombre del lugar"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default BettingPlaceForm;
