
import BuyRegisterView from "../components/bets_register/BuyRegisterView";

const BettingBuyRegister = () => {
  return (
    <>
      <BuyRegisterView />
    </>
  );
};

export default BettingBuyRegister;
