import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";

import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";
import Operator, { newOperator } from "../../models/operator";


interface OperatorFormProps {
  initialOperator?: Operator;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const OperatorForm: React.FC<OperatorFormProps> = ({
  initialOperator,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingOperator, setEditingOperator] = useState<Operator>(
    initialOperator ? initialOperator : newOperator()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );

  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingOperator({
      ...editingOperator,
      name: e.target.value,
    });
  };

  const onDocumentNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingOperator({
      ...editingOperator,
      documentNumber: e.target.value,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendOperator = {
      ...editingOperator,
    };
    let requestPromise: Promise<ItemRequestStatus<Operator>>;
    if (editingOperator.id === undefined || editingOperator.id === 0) {
      requestPromise = createItem<Operator>("/operators/create/", toSendOperator);
    } else {
      requestPromise = updateItem<Operator>(
        `/operators/${toSendOperator.id}/`,
        toSendOperator
      );
    }

    const operatorStatus = await requestPromise;

    if (operatorStatus.status !== SUCCESS) {
  
      if (operatorStatus.errors !== undefined) {
        setErrors(operatorStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (operatorStatus.detail !== undefined) {
        message = operatorStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingOperator(newOperator());
  };

  useEffect(() => {
    setEditingOperator(initialOperator ? initialOperator : newOperator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOperator]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingOperator.name}
                onChange={onNameChange}
                placeholder="Ej: Nombre del Operador"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Documento</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingOperator.documentNumber}
                onChange={onDocumentNumberChange}
                placeholder="Ej: Nro de documento"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("documentNumber", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default OperatorForm;
